import {navigateTo} from "nuxt/app";
import {MainLayoutStore} from "~/layouts/main-layout-store";
import {UserStore} from '~/store/user-store';

export default defineNuxtRouteMiddleware((to, from) => {
    const userStore = UserStore();
    const hasLogin = userStore.hasLogin();

    //已登录时如果在登录/注册页 直接跳转到首页
    if (hasLogin) {
        if (to.path === '/login/page' || to.path === '/register/page') {
            return navigateTo('/')
        }
    }

    //未登录跳转到登录页
    if (!hasLogin) {
        //已经重定向过 直接返回
        if (to.path === '/login/page') {
            return true
        }
        //已经在 登录/注册页 直接返回
        if (from.path === '/login/page' || from.path === '/register/page') {
            return true
        }
        return navigateTo('/login/page')
    }

    //默认路由跳转到 我的佣金页
    if (to.path === '/') {
        return navigateTo('/wallet/usdt-bill/page')
    }

    //路由切换后关闭侧边栏
    const mainLayoutStore = MainLayoutStore();
    mainLayoutStore.sidebarOpen = false;
})
